export default {
	action: {
		cancel: "Cancelar",
		"save-changes": "Guardar cambios",
		change: "Cambiar",
		reset: "Restablecer",
		delete: "Eliminar",
		enable: "Habilitar",
		disable: "Deshabilitar",
		submit: "Enviar",
		confirm: "¿Seguro?",
	},
	profile: {
		link: "Perfil",
		edit: "Editar perfil",
		joined: "Se unió en {{date}}",
		photo: {
			title: "Foto",
			change: "Cambiar foto de perfil",
			save: "Guardar foto de perfil",
		},
		"2fa": {
			"enable-cta": "Habilitar autenticación de dos factores",
			"disable-cta": "Deshabilitar autenticación de dos factores",
			"enabled-short": "Autenticación de dos factores habilitada",
			"enabled-long": "Tienes habilitada la autenticación de dos factores.",
			"disabled-long": "No has habilitado la autenticación de dos factores.",
			description:
				"La autenticación de dos factores agrega una capa extra de seguridad a tu cuenta. Necesitarás ingresar un código de una aplicación autenticadora para iniciar sesión.",
		},
		"change-email": {
			link: "Cambiar email {{email}}",
			title: "Cambiar email",
			info: "Te enviaremos un email de confirmación al nuevo email.",
			notice: "También se enviará un email de notificación al email anterior {{email}}.",
			new: "Nuevo email",
			submit: "Enviar confirmación",
			"email-exists": "Un usuario ya existe con este email",
		},
		password: {
			change: "Cambiar contraseña",
			create: "Crear contraseña",
			current: "Contraseña actual",
			new: "Nueva contraseña",
			"confirm-new": "Confirmar nueva contraseña",
		},
		connections: {
			title: "Conexiones",
			manage: "Gestionar conexiones",
		},
		"download-data": "Descargar datos",
		"account-delete": "Eliminar cuenta",
		"account-deleted-title": "Cuenta eliminada",
		"account-deleted-text": "Todos los datos de tu cuenta han sido eliminados",
		"username-exists": "Un usuario ya existe con este nombre",
		"single-session": "Esta es tu única sesión",
		"close-sessions": "Cerrar tus otras {{count}} sesiones",
	},
	logout: "Cerrar sesión",
	login: {
		title: "Iniciar sesión",
		heading: "¡Hola de nuevo!",
		subtitle: "Por favor, introduce tu email y contraseña.",
		"remember-me": "Recuérdame",
		"forgot-password": "¿Olvidaste tu contraseña?",
		"signup-link": "<0>¿No tienes cuenta?</0> <1>Crear una cuenta</1>",
		submit: "Iniciar sesión",
	},
	signup: {
		title: "Registrarse",
		heading: "¡Comienza aquí!",
		subtitle: "Por favor, introduce tu email.",
		submit: "Registrarse",
	},
	user: {
		email: "Email",
		password: "Contraseña",
		username: "Nombre de usuario",
		name: "Nombre",
	},
	verify: {
		"check-email": "Verificar to email",
		"email-sent": "Te hemos enviado un código de verificación.",
		code: "Código de verificación",
		submit: "Verificar",
		"check-2fa-title": "Revisa tu app de 2FA",
		"check-2fa-description": "Por favor, ingresa tu código de 2FA para verificar tu identidad.",
		"invalid-verification-type": "Tipo de verificación inválido",
		"invalid-code": "Código de verificación inválido",
	},
	onboarding: {
		title: "Crea tu cuenta en klickstack",
		welcome: "¡Te damos la bienvenida!",
		"welcome-with-email": "¡Te damos la bienvenida, {{email}}!",
		subtitle: "Por favor, complete el siguiente formulario",
		"agree-to-terms":
			"¿Estás de acuerdo con nuestros <0>Términos de Servicio</0> y <1>Política de Privacidad</1>?",
		"create-account": "Crea tu cuenta",
		thanks: "¡Gracias por unirte a klickstack!",
	},
}
