export default {
	action: {
		cancel: "Cancel",
		"save-changes": "Save changes",
		change: "Change",
		reset: "Reset",
		delete: "Delete",
		enable: "Enable",
		disable: "Disable",
		submit: "Submit",
		confirm: "Are you sure?",
	},
	profile: {
		link: "Profile",
		edit: "Edit profile",
		joined: "Joined on {{date}}",
		photo: {
			title: "Photo",
			change: "Change profile photo",
			save: "Save photo",
		},
		"2fa": {
			"enable-cta": "Enable 2FA",
			"disable-cta": "Disable 2FA",
			"enabled-short": "2FA is enabled",
			"enabled-long": "You have enabled two-factor authentication.",
			"disabled-long": "You have not enabled two-factor authentication yet.",
			description:
				"Two factor authentication adds an extra layer of security to your account. You will need to enter a code from an authenticator app to log in.",
		},
		"change-email": {
			link: "Change email from {{email}}",
			title: "Change email",
			info: "We will send a confirmation email to the new address.",
			notice: "An email notice will also be sent to your old address {{email}}.",
			new: "New email",
			submit: "Send confirmation",
			"email-exists": "This email is already in use.",
		},
		password: {
			change: "Change password",
			create: "Create a password",
			current: "Current password",
			new: "New password",
			"confirm-new": "Confirm new password",
		},
		connections: {
			title: "Connections",
			manage: "Manage connections",
		},
		"download-data": "Download your data",
		"account-delete": "Delete your account",
		"account-deleted-title": "Account deleted",
		"account-deleted-text": "All of your data has been deleted",
		"username-exists": "A user already exists with this username",
		"single-session": "This is your only session",
		"close-sessions": "Sign out of {{count}} other sessions",
	},
	logout: "Logout",
	login: {
		title: "Log in",
		heading: "Welcome back!",
		subtitle: "Please, enter your email and password.",
		"remember-me": "Remember me",
		"forgot-password": "Forgot password?",
		"signup-link": "<0>New here?</0> <1>Create an account</1>",
		submit: "Log in",
	},
	signup: {
		title: "Sign up",
		heading: "Let’s start your journey!",
		subtitle: "Please, enter your email.",
		submit: "Sign up",
	},
	user: {
		email: "Email",
		password: "Password",
		username: "Username",
		name: "Name",
	},
	verify: {
		"check-email": "Check your email",
		"email-sent": "We've sent you a code to verify your email address.",
		code: "Verification code",
		submit: "Verify",
		"check-2fa-title": "Check your 2FA app",
		"check-2fa-description": "Please enter your 2FA code to verify your identity.",
		"invalid-verification-type": "Invalid verification type",
		"invalid-code": "Invalid code",
	},
	onboarding: {
		title: "Create your klickstack account",
		welcome: "Welcome",
		"welcome-with-email": "Welcome aboard {{email}}!",
		subtitle: "Please, enter your details below",
		"agree-to-terms": "Do you agree to our <0>Terms of Service</0> and <1>Privacy Policy</1>?",
		"create-account": "Create an account",
		thanks: "Thanks for signing up!",
	},
}
